.tickbox {
	input[role="switch"] {
		width: 55px;
		min-width: 55px;

		&::before,
		&::after {
			--tickbox-switch-color: var(--tickbox-switch-color-inactive, var(--tickbox-color-inactive));

			border-color: var(--tickbox-switch-color);
			transition:
				border-color 125ms ease,
				background-color 125ms ease,
				transform 125ms ease;
		}

		&::before {
			width: 10px; height: 10px;
			border-radius: var(--tickbox-switch-mark-radius, 10px);
			background-color: var(--tickbox-switch-color);
			transform: translateX(-6px);
		}

		&::after {
			width: 36px; height: 24px;
			border-radius: var(--tickbox-switch-border-radius, 24px);
			background-color: transparent;
			border-width: var(--tickbox-border-width, 2px);
			border-style: solid;
		}

		&:checked {
			&::before,
			&::after {
				--tickbox-switch-color: var(--tickbox-switch-color-active, var(--tickbox-color-active));
			}

			&::before {
				transform: translateX(6px) scale(1.4);
			}
		}

		&:disabled {
			&::before,
			&::after {
				--tickbox-switch-color: var(--tickbox-switch-color-disabled, var(--tickbox-color-disabled));
			}
		}
	}
}