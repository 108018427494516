.signup-button {
  display: grid;
  place-content: center;
  letter-spacing: .5px;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  touch-action: manipulation;
  appearance: none;
  border: none;
  margin: 0;
  text-decoration: none;
  text-transform: uppercase;
  font-weight: 700;
  color: white;
  background-size: 300% 100%;
  transition: all 375ms ease;
  padding: 0 20px;
  width: 100%;
  height: 48px;
  border-radius: 48px;
  font-size: 16px;
  background-image: linear-gradient(to right, #AC09E6, #EB0291, #EB0291);

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      background-position: 100% 100%;
    }
  }
}
