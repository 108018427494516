@use "base/mixins" as *;

.logotype-champion {
  --size-factor: 1;
  --c-logo-text-color: White;

  display: grid;
  grid-template: auto / auto;
  gap: 4px 8px;
  justify-items: unset;
  margin: auto;
  width: fit-content;

  @include min(phone-landscape) {
    gap: 8px 0;
    justify-items: center;
  }

  &__star {
    width: calc(5em * var(--size-factor));
    aspect-ratio: 1;

    @include max(phone-landscape) {
      grid-row: 1 / 3;
      grid-column: 1 / 2;
    }
  }

  &__champion {
    align-self: end;
    height: calc(1.6em * var(--size-factor));
    aspect-ratio: 20 / 2.8;
    fill: var(--c-logo-text-color);

    @include max(phone-landscape) {
      grid-row: 1 / 2;
      grid-column: 2 / 3;
    }
  }

  &__slots {
    height: calc(1.6em * var(--size-factor));
    aspect-ratio: 12 / 2.8;
    fill: var(--c-logo-text-color);

    @include max(phone-landscape) {
      grid-row: 2 / 3;
      grid-column: 2 / 3;
    }
  }
}
