.tickbox {
	label {
		position: relative;
		display: inline-flex;
		flex-direction: column;
		justify-content: center;
		overflow: hidden;
		max-width: 100%;
		font-weight: var(--tickbox-label-font-weight, 400);
		line-height: 150%;
		font-size: var(--tickbox-label-font-size);
		color: var(--tickbox-label-font-color);
		letter-spacing: .01px;
		-moz-osx-font-smoothing: grayscale;
		-webkit-font-smoothing: antialiased;
		-webkit-tap-highlight-color: transparent;
		user-select: none;
		pointer-events: auto;

		span {
			padding: 1px 0;
		}

		span:first-child {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}

		span + span {
			line-height: 125%;
			font-size: var(--tickbox-desc-font-size);
			color: var(--tickbox-desc-font-color);
			font-weight: var(--tickbox-desc-font-weight, 400);
		}
	}

	input:disabled + label {
		pointer-events: none;
		color: var(--tickbox-disabled-font-color);

		span + span {
			color: var(--tickbox-disabled-font-color);
		}
	}
}