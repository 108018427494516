.select-option {
  &--signup-currency {
    .select-option-trigger,
    .select-option-list-item {
      padding-left: 54px;

      &--rub {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI2ZmZiIgZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMCAwaDI0djZIMFYwWiIgY2xpcC1ydWxlPSJldmVub2RkIi8+PHBhdGggc3Ryb2tlPSIjRDBEMEQwIiBkPSJNLjUuNWgyM3YxN0guNVYuNVoiLz48cGF0aCBmaWxsPSIjMDAzOUE2IiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDZoMjR2NkgwVjZaIiBjbGlwLXJ1bGU9ImV2ZW5vZGQiLz48cGF0aCBmaWxsPSIjRDUyQjFFIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGQ9Ik0wIDEyaDI0djZIMHYtNloiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg==");
      }

      &--kzt {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAxQTVDOCIgZD0iTTAgMGgyNHYxOEgweiIvPjxwYXRoIHN0cm9rZT0iI0ZGRTcwMCIgc3Ryb2tlLXdpZHRoPSIxLjI1IiBkPSJtOCAxMSA0LjUgM00xMS41IDE0bDQuNS0zIi8+PGNpcmNsZSBjeD0iMTIiIGN5PSI4IiByPSIzIiBmaWxsPSIjRkZFNzAwIi8+PC9zdmc+");
      }

      &--azn {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzUwOUUyRiIgZD0iTTI0IDEySDB2NmgyNHYtNloiLz48cGF0aCBmaWxsPSIjRUYzMzQwIiBkPSJNMjQgNkgwdjZoMjRWNloiLz48cGF0aCBmaWxsPSIjMDBCNUUyIiBkPSJNMjQgMEgwdjZoMjRWMFoiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJNMTEuMSAxMS43Yy44ODMgMCAxLjY2OC0uNDI0IDIuMTYtMS4wOGEyLjI1IDIuMjUgMCAxIDEgMC0zLjI0MkEyLjcgMi43IDAgMSAwIDExLjEgMTEuN1oiLz48cGF0aCBmaWxsPSIjZmZmIiBkPSJtMTQuMzg1IDguMzA3LS4yODctLjgwNy0uMjg3LjgwNy0uNzczLS4zNjcuMzY3Ljc3My0uODA3LjI4Ny44MDcuMjg3LS4zNjcuNzc0Ljc3My0uMzY4LjI4Ny44MDcuMjg3LS44MDcuNzc0LjM2OC0uMzY4LS43NzQuODA3LS4yODctLjgwNy0uMjg3LjM2OC0uNzczLS43NzQuMzY3WiIvPjwvc3ZnPg==");
      }

      &--try {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iI0UzMEExNyIgZD0iTTAgMGgyNHYxOEgwVjBaIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTEzLjI5NiA4LjQzOSAxMS42MjYgOWwxLjY3LjU2MXYxLjgxN2wxLjAzMy0xLjQ3TDE2IDEwLjQ3IDE0Ljk2NyA5IDE2IDcuNTNsLTEuNjcxLjU2Mi0xLjAzMy0xLjQ3VjguNDRaIi8+PHBhdGggZmlsbD0iI2ZmZiIgZD0iTTkuNTUgMTMuNjc1YTQuODczIDQuODczIDAgMCAwIDIuMzczLTIuMDAxIDMuODQ3IDMuODQ3IDAgMCAxLTEuOTcxIDEuMjE1IDMuNzUgMy43NSAwIDAgMS0yLjI5MS0uMTU0IDMuODkzIDMuODkzIDAgMCAxLTEuODA0LTEuNDY5QTQuMDk1IDQuMDk1IDAgMCAxIDUuMTc3IDljMC0uODEuMjM3LTEuNi42OC0yLjI2NmEzLjg5MyAzLjg5MyAwIDAgMSAxLjgwNC0xLjQ2OSAzLjc1IDMuNzUgMCAwIDEgMi4yOS0uMTU0Yy43NjIuMTkgMS40NS42MTMgMS45NzIgMS4yMTVBNC44NzMgNC44NzMgMCAwIDAgOS41NSA0LjMyNGE0LjY4NyA0LjY4NyAwIDAgMC0zLjA2LS4xMjYgNC44MzggNC44MzggMCAwIDAtMi41MiAxLjc5OUMzLjM0IDYuODYzIDMgNy45MTcgMyA5YzAgMS4wODMuMzQgMi4xMzcuOTcgMy4wMDNhNC44MzggNC44MzggMCAwIDAgMi41MiAxLjggNC42ODggNC42ODggMCAwIDAgMy4wNi0uMTI4WiIvPjwvc3ZnPg==");
      }

      &--mxn {
        background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNCIgaGVpZ2h0PSIxOCIgZmlsbD0ibm9uZSI+PHBhdGggZmlsbD0iIzAwNjg0NyIgZD0iTTAgMGg3djE4SDB6Ii8+PHBhdGggZmlsbD0iI0NFMTEyNiIgZD0iTTE3IDBoN3YxOGgtN3oiLz48cGF0aCBmaWxsPSIjRUVFIiBkPSJNNyAwaDEwdjE4SDd6Ii8+PHJlY3Qgd2lkdGg9IjQiIGhlaWdodD0iNSIgeD0iMTEiIHk9IjUiIGZpbGw9IiNDMTY5NEYiIHJ4PSIyIi8+PHBhdGggZmlsbD0iI0E2RDM4OCIgZD0iTTggMTBhNCA0IDAgMCAwIDggMGgtMWEzIDMgMCAwIDEtNiAwSDhaIi8+PHBhdGggZmlsbD0iIzU1QUNFRSIgZD0iTTEyIDExLjgzM2MxLjEwNSAwIDItLjI5OCAyLS42NjYgMC0uMzY4LS44OTUtLjY2Ny0yLS42NjdzLTIgLjI5OS0yIC42NjdjMCAuMzY4Ljg5NS42NjYgMiAuNjY2WiIvPjxwYXRoIGZpbGw9IiNGRkNDNEQiIGQ9Ik0xMi41IDExLjgzM2MuNTUyIDAgMS0uMjk4IDEtLjY2NiAwLS4zNjgtLjQ0OC0uNjY3LTEtLjY2N3MtMSAuMjk5LTEgLjY2N2MwIC4zNjguNDQ4LjY2NiAxIC42NjZaIi8+PHBhdGggZmlsbD0iIzVDOTEzQiIgZD0iTTkgN2gxdjJIOXoiLz48L3N2Zz4=");
      }

      &--rub,
      &--kzt,
      &--azn,
      &--try,
      &--mxn {
        background-position: center left 18px;
        background-size: 24px 18px;
        background-repeat: no-repeat;
      }
    }
  }
}
