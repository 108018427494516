.character-elements {
  pointer-events: none;
  position: absolute;
  inset: 0;
  margin: auto;
  display: flex;
  justify-content: space-between;
  width: min(80%, 840px);
  height: max(50vmin, 360px);
  transform: translateY(9vmin);

  .image-container {
    width: fit-content;
    filter: drop-shadow(0 0 1px rgba(0, 0, 0, 0.35));
  }

  .image-container--character-one {
    transform: translateX(-80px);
  }

  .image-container--character-two {
    transform: translateX(80px);
  }
}
