@use "base/mixins" as *;
@use "sass:list";

.floating-particles {
  position: absolute;
  inset: 0;
  z-index: -1;
  margin: auto;
  max-width: 1600px;

  @include max(tablet-portrait) {
    overflow: hidden;
  }

  &__item {
    box-shadow: 0 0 0 4px inset Gray;
    position: absolute;
    width: min(20vmin, 160px);
    aspect-ratio: 1;
    border-radius: 50%;
    will-change: transform;
    animation: circular-motion linear infinite;

    $positions: (
      1: (null, null, null, null, 10s),
      2: (25%, auto, auto, 8%, 8s),
      3: (28%, 24%, auto, auto, 7.5s),
      4: (4%, 7%, auto, auto, 9s),
      5: (auto, 6%, 12%, auto, 7s),
      6: (auto, auto, 12%, 10%, 9.5s)
    );

    @each $index, $values in $positions {
      &:nth-child(#{$index}) {
        inset: list.nth($values, 1) list.nth($values, 2) list.nth($values, 3) list.nth($values, 4);
        animation-duration: list.nth($values, 5);
        animation-direction: if($index % 2 == 0, reverse, normal);
      }
    }
  }

  @keyframes circular-motion {
    from {
      transform: rotate(0deg) translate(2vmin) rotate(0deg);
    }
    to {
      transform: rotate(360deg) translate(2vmin) rotate(-360deg);
    }
  }
}
