@use "signup-currency" as *;
@use "signup-terms" as *;
@use "signup-button" as *;
@use "signup-container" as *;
@use "signup-styling" as *;

.signup-form {
  position: relative;
  width: min(100%, 460px);
  margin: auto;
  display: grid;
  gap: 15px 0;

  //input:-webkit-autofill,
  //input:-webkit-autofill:hover,
  //input:-webkit-autofill:focus,
  //input:-webkit-autofill:active {
  //  background-clip: text;
  //  -webkit-text-fill-color: #000;
  //  transition: background-color 5000s ease-in-out 0s;
  //  box-shadow: inset 0 0 20px 20px #0000;
  //}

  input:-webkit-autofill,
  input:-webkit-autofill:focus,
  input[type="password"]:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[data-autocompleted] {
    background-color: transparent !important;
  }
}

.text-field-helper {
  display: none;

  @at-root .input--error + & {
    display: block;
  }
}
