.payment-brands {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 0 20px;
  margin: auto;
  width: min(400px, 100% - 30px);

  &__item {
    display: grid;
    place-content: center;
  }

  .svg-icon--payment-brands {
    width: 100%;
    aspect-ratio: 100 / 54;
    fill: var(--c-white);
  }
}
