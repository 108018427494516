.page {
  display: grid;
  gap: var(--v-offset-sm) 0;
  padding: var(--v-offset-lt) 0 var(--v-offset-lt);

  @supports (min-height: 100dvh) {
    min-height: 100dvh;
  }

  @supports not (min-height: 100dvh) {
    min-height: 100vh;
  }

  &__logotype {
    place-self: center;
  }

  &__gain {
    place-self: center;
  }

  &__wheel {
    position: relative;
    place-self: center;
  }

  &__payment {
    place-self: center;
    width: 100%;
  }
}
