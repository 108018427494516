.tickbox {
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: flex-start;
	width: 100%;
	padding: 2px 0;
	pointer-events: none;

	input {
		position: relative;
		width: 48px;
		min-width: 48px;
		height: 48px;
		min-height: 48px;
		pointer-events: auto;
		appearance: none;
		cursor: pointer;
		background: none;
		backface-visibility: hidden;
		user-select: none;
		-webkit-tap-highlight-color: transparent;

		&[type='checkbox']:not([role="switch"]),
		&[type='radio']:not([role="switch"]) {
			margin-left: -14px;
			margin-right: 10px;
		}

		&[role="switch"] {
			margin-left: -10px;
			margin-right: 15px;
		}

		&:disabled {
			pointer-events: none;
			cursor: default;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			inset: 0;
			margin: auto;
		}
	}

	&--rtl {
		justify-content: space-between;

		input {
			order: 2;

			&[type='checkbox']:not([role="switch"]),
			&[type='radio']:not([role="switch"]) {
				margin-left: 10px;
				margin-right: -14px;
			}

			&[role="switch"] {
				margin-left: 15px;
				margin-right: -10px;
			}
		}

		label {
			order: 1;
		}
	}
}