.text-field-helper {
	display: flex;
	justify-content: flex-end;
	padding: 4px 17px 0;
	font-size: var(--tf-font-size-helper);
	line-height: 1.3;
	color: var(--tf-c-text-helper);

	&__text {
		padding-right: 10px;
		flex: 1;
	}

	&__counter {
		white-space: nowrap;
		text-align: right;
	}
}
