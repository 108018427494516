.signup-terms {
  margin: -10px 0;
  padding: 0;
  display: flex;

  label {
    margin-left: -10px;
    font-size: 13px;

    span:first-child {
      white-space: unset;
      text-overflow: unset;
      overflow: auto;
    }
  }

  a {
    &:hover {
      text-decoration: none;
    }
  }
}
