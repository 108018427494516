:root {
  // colors
  --c-white: rgb(255 255 255);

  // NPM package typographics
  --max-vw: 1600;
  --min-vw: 600;
  --max-fs: 1;
  --min-fs: .6;
  --font-family-base: "Inter", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --line-height-base: 1.5;
  --line-height-heading: 1.3em;

  // offsets
  --v-offset-lt: min(20px, 1.2vmax);
  --v-offset-sm: min(40px, 2.4vmax);
  --v-offset-md: min(80px, 4.8vmax);
  --v-offset-lg: min(160px, 9.6vmax);
}
