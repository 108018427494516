.select-option {
  .select-option-list {
    display: none;
    overflow: hidden visible;
    position: absolute;
    inset: 100% auto auto 0;
    z-index: 2;
    width: 100%;
    max-height: 180px;
    margin-top: 4px;
    padding-top: 8px;
    padding-bottom: 8px;
    border-radius: var(--so-border-radius-list, 5.5px);
    background-color: var(--so-background-list, White);
    box-shadow: var(--so-list-shadow, 0 5px 5px -3px rgb(0 0 0 / 20%), 0 8px 10px 1px rgb(0 0 0 / 14%), 0 3px 14px 2px rgb(0 0 0 / 12%));

    &::-webkit-scrollbar {
      width: 12px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: var(--so-color-scrollbar-thumb, Silver);
      border: 4px solid var(--so-color-scrollbar-track, White);
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: var(--so-color-scrollbar-hover, Gray);
    }

    &::-webkit-scrollbar-track {
      background-color: var(--so-color-scrollbar-track, White);
    }
  }

  &.select-option--downstairs .select-option-list {
    inset: auto auto 100% 0;
    margin-top: unset;
    margin-bottom: 12px;
  }

  &.select-option--opened .select-option-list {
    display: block;
  }
}
