.dialog-lite {
  position: fixed;
  inset: 0;
  z-index: var(--z-index-dialog-lite, 992);
  width: 100vw;
  overflow: clip auto;

  &--in {
    -webkit-overflow-scrolling: touch;
  }

  &--out {
    visibility: hidden;
    pointer-events: none;
  }

  &__backdrop {
    position: fixed;
    inset: 0;
    margin: auto;
    z-index: var(--z-index-dialog-lite-backdrop, 993);

    .dialog-lite--in & {
      background-color: hsla(240deg 22% 6% / 82%);
      transition: background-color 400ms cubic-bezier(.61, 1, .88, 1);
    }

    .dialog-lite--out & {
      pointer-events: none;
      background-color: hsla(200deg 2% 6% / 0%);
      transition: background-color 500ms cubic-bezier(0, 0, 0.5, 1);
    }
  }

  &__container {
    pointer-events: none;
    position: relative;
    z-index: var(--z-index-dialog-lite-container, 994);
    display: grid;
    place-content: center;
    width: 100vw;

    @supports (min-height: 100dvh) {
      min-height: 100dvh;
    }

    @supports not (min-height: 100dvh) {
      min-height: 100vh;
    }

    &-inner {
      position: relative;
      margin: 20px;

      .dialog-lite--in & {
        pointer-events: auto;
        opacity: 1;
        transform: translateY(0);
        transition:
          opacity 400ms cubic-bezier(.61, 1, .88, 1),
          transform 400ms cubic-bezier(.61, 1, .88, 1);
      }

      .dialog-lite--out & {
        pointer-events: none;
        opacity: 0;
        transform: translateY(40px);
        transition:
          opacity 500ms cubic-bezier(0, 0, 0.5, 1),
          transform 550ms cubic-bezier(.22, 1, .5, .95);
      }
    }
  }

  &-close-button {
    cursor: pointer;
    position: absolute;
    inset: 0 0 auto auto;
    display: grid;
    place-content: center;
    width: 50px;
    height: 50px;

    .svg-icon {
      width: 24px;
      height: 24px;
      fill: black;
    }
  }
}
