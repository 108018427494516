.gain-message {
  position: relative;
  text-align: center;
  text-wrap: balance;
  filter: drop-shadow(0 0 0.5em rgba(0, 0, 0, 0.5));
  will-change: transform;

  &__item {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    color: White;
    line-height: 1;
    font-size: 4.8em;
    font-weight: 900;

    &:nth-child(2) {
      color: White;
      transform: rotate(-2.5deg);
      animation: libration 1250ms infinite alternate ease-in-out;

      @-webkit-keyframes libration {
        to {
          transform: rotate(2.5deg);
        }
      }
    }

    &:nth-child(3) {
      color: White;
      transform: rotate(-1.5deg);
      animation: libration 1250ms infinite alternate ease-in-out;

      @-webkit-keyframes libration {
        to {
          transform: rotate(1.5deg);
        }
      }
    }

    &:not(:first-of-type) {
      margin-top: -1em;
    }

    .char {
      backface-visibility: hidden;
    }

    .word,
    .char {
      display: inline-block;
    }

    .whitespace {
      white-space: pre;
    }

    &.slide-null .char {
      transform: translateY(-0.5em) rotateX(-90deg);
    }

    &.slide-in .char {
      transform: translateY(0) rotateX(0deg);
      animation: slide-in 425ms cubic-bezier(.3, 0, .7, 1) both;
      animation-delay: calc(50ms * var(--char-index));

      @keyframes slide-in {
        to {
          transform: translateY(-0.5em) rotateX(-90deg);
        }
      }
    }

    &.slide-out .char {
      transform: translateY(0.5em) rotateX(90deg);
      animation: slide-out 425ms cubic-bezier(.3, 0, .7, 1) both;
      animation-delay: calc(50ms * var(--char-index));

      @keyframes slide-out {
        to {
          transform: translateY(0) rotateX(0deg);
        }
      }
    }
  }
}
