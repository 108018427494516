@use "base/mixins" as *;

$devices: (
  "tablet-landscape",
  "tablet-portrait",
  "phone-landscape",
  "phone-portrait",
  "touch"
);

@each $device in $devices {
  [data-device="max-#{$device}"] {
    @include max($device) {
      display: none;
    }
  }

  [data-device="min-#{$device}"] {
    @include min($device) {
      display: none;
    }
  }
}
