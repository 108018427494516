@use "base/mixins" as *;

.signup-container {
  padding: 15px;
  background-color: White;
  border-radius: 17.5px;

  @include min(tablet-portrait) {
    padding: 20px;
  }
}
