.select-option {
  .select-option-trigger {
    max-width: 100%;
    height: 48px;
    line-height: 48px;
    padding-left: 17px;
    padding-right: 34px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--so-color-trigger, Gray);
  }

  &.select-option--dense .select-option-trigger {
    height: 36px;
    line-height: 36px;
  }
}
