.tickbox {
	--tickbox-color-active-h: 210;
	--tickbox-color-active-s: 100%;
	--tickbox-color-active-l: 56%;
	--tickbox-color-active-a: 1;
	--tickbox-color-active: hsla(var(--tickbox-color-active-h) var(--tickbox-color-active-s) var(--tickbox-color-active-l) / var(--tickbox-color-active-a));
	--tickbox-color-inactive-h: 0;
	--tickbox-color-inactive-s: 0%;
	--tickbox-color-inactive-l: 66%;
	--tickbox-color-inactive-a: 1;
	--tickbox-color-inactive: hsla(var(--tickbox-color-inactive-h) var(--tickbox-color-inactive-s) var(--tickbox-color-inactive-l) / var(--tickbox-color-inactive-a));
	--tickbox-color-active-highlight: hsla(var(--tickbox-color-active-h) var(--tickbox-color-active-s) var(--tickbox-color-active-l) / 26%);
	--tickbox-color-inactive-highlight: hsla(var(--tickbox-color-inactive-h) var(--tickbox-color-inactive-s) var(--tickbox-color-inactive-l) / 26%);
	--tickbox-color-disabled: silver;
	--tickbox-transition-duration: 475ms;
	--tickbox-transition-timing: cubic-bezier(0.4, 0.08, 0.38, 0.9);
	--tickbox-label-font-size: 15px;
	--tickbox-desc-font-size: 12px;
	--tickbox-label-font-color: black;
	--tickbox-desc-font-color: gray;
	--tickbox-disabled-font-color: silver;
}